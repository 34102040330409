
import { getCookie, setCookie } from "../../utils/cookie";
import time from "../../utils/time";

// timeout
const initLeadHunter1Popup = () => {
  const leadHunter = document.querySelector('#popup-leadhunter1');
  let timeout;
  let after;

  if (leadHunter) {
    console.log(leadHunter.dataset)
    timeout = leadHunter.dataset.timeout;
    after = leadHunter.dataset.after;
  }

  function leadHunterPopup1() {
    let flag_check = false;

    if (!flag_check) {
      let show_time = parseInt(timeout);
      let value_show = parseInt(after);
      let flag_show = true;

      if (!isNaN(getCookie("showidget")) && parseInt(getCookie("showidget")) && time() - parseInt(getCookie("showidget")) < value_show) {
        flag_show = false;
      }
      console.log('FLAG - ' + flag_show);
      console.log(value_show);
      if (show_time && flag_show) {
        setTimeout(function () {
          if ($("#popup-leadhunter1").length) {
            document.getElementById('lh2-closer').click()
            document.getElementById('lh1-opener').click()
            setCookie("showidgetgr2", time(), { 'path': '/', 'domain': 'studservis.ru/gotovye-raboty' });
            setCookie("showidgetgr", time(), { 'path': '/', 'domain': 'studservis.ru/gotovye-raboty' });
          }
        }, show_time * 1000);
      }
    }
  }

  if (leadHunter) {
    leadHunterPopup1();
  }
};

export default initLeadHunter1Popup;
