
import { closeLoaderGR, openLoaderGR } from "../loaders/loader";
import MainSettings from "../../data/mainSettings";
import { getCookie } from "../../utils/cookie";

const createOrder = (form) => {
  let elemForm;
  if (typeof form === 'string') {
    elemForm = document.querySelector(form);
  } else {
    elemForm = form;
  }
  const formArray = $(elemForm).serializeArray();
  let filesArray = $(form).find("#file-upload");
  const formData = new FormData(elemForm);
  const formDataUpdateUser = new FormData();

  for (let elem of formArray) {
    if (elem.name === 'phone') {
      formDataUpdateUser.append(elem.name, elem.value);
    }
  }

  if (getCookie("label_id")) {
    formData.append("label_id", getCookie("label_id"));
  }

  if (!!filesArray.length) {
    filesArray = filesArray[0].files;
    Array.from(filesArray).forEach((file) => {
      formData.append("files[]", file);
    });
  }

  ym(MainSettings.metrika, "reachGoal", "ORDER");

  const token = $('meta[name="csrf-token"]').attr('content');

  openLoaderGR();
  fetch('/gotovye-raboty/forms/create-order/', {
    method: 'POST',
    headers: {
      'X-CSRF-TOKEN': token
    },
    body: formData
  }).then(response => response.json())
    .then((result) => {
      let newLocation;
      if (typeof result.link !== "undefined" && result.link.length > 0) {
        newLocation = result.link;
      } else if (result.order_id && result.action === "userIsset") {
        newLocation = MainSettings.lk_url + "orders/newOrder/id=" + result.order_id + "/new/";
      } else {
        newLocation = MainSettings.lk_url;
      }
      // Костыль, для отправки события updateUser
      // Так как там на стороне ЦРМ выполняется нужная функция
      if (result.user_id && formDataUpdateUser.has('phone')) {
        formDataUpdateUser.append('user_id', result.user_id);
        fetch('/gotovye-raboty/forms/update-user/', {
          method: 'POST',
          headers: {
            'X-CSRF-TOKEN': token
          },
          body: formDataUpdateUser
        }).then((response) => {
          console.log(response);
        }).finally((data) => {
          console.log(data);
          return window.location.href = newLocation;
        });
      } else {
        return window.location.href = newLocation;
      }
    }).catch((error) => {
      console.log(error)
      closeLoaderGR();
      alert("Ошибка отправки");
      return false;
    })

  return false;
};

export default createOrder;
