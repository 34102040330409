import MainSettings from "../data/mainSettings";

const initYandexEvent = () => {
  //300 руб на первый заказ
  $(document).on('change', '.form__select', function () {
    ym(MainSettings.metrika, "reachGoal", "WorkSelect")
  });

  //Выбор темы
  $(document).on('change', 'input[name=theme]', function () {
    ym(MainSettings.metrika, "reachGoal", "worktype")
  });

  //Ввод имейла
  $(document).on('change', 'input[name=email]', function () {
    ym(MainSettings.metrika, "reachGoal", "insertemail")
  });

  //Узнать стоимость
  $(document).on('click', '.button__text', function () {
    ym(MainSettings.metrika, "reachGoal", "sendform")
  });


  //Узнать стоимость
  $(document).on('change', '.search__input_main', function () {
    ym(MainSettings.metrika, "reachGoal", "inserttopic")
  });

  //Ввод электронной почты
  $(document).on('change', '.search__input_second', function () {
    ym(MainSettings.metrika, "reachGoal", "insertemail")
  });

  //Клип по кнопке "Узнать стоимость"
  $(document).on('click', '.search__button', function () {
    ym(MainSettings.metrika, "reachGoal", "getprice")
  });


  //Клип по "Скачать в 1 клик"
  $(document).on('click', '.goods__button', function () {
    ym(MainSettings.metrika, "reachGoal", "oneclick")
  });

  //Ввод электронной почты
  $(document).on('change', '.popup__input', function () {
    ym(MainSettings.metrika, "reachGoal", "insertemail")
  });

  //Клик по кнопке "Скачать"
  $(document).on('click', '.button_text', function () {
    ym(MainSettings.metrika, "reachGoal", "clickdownload")
  });


  //Ввод электронной почты
  $(document).on('change', '.form__input', function () {
    ym(MainSettings.metrika, "reachGoal", "insertemail")
  });


  //Клик по кнопке "Узнать скидку"
  $(document).on('click', '.button_color-pink', function () {
    ym(MainSettings.metrika, "reachGoal", "getsale")
  });
};

export default initYandexEvent;
