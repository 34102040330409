import "./vendor.js";

import { iosVhFix } from './utils/ios-vh-fix.js';
import createSelectsOptions from "./modules/createSelectsOptions";
import initCalculatorForm from "./modules/formHandlers/initCalculatorForm.js";
import initCalculateFormValidate from "./modules/formHandlers/initCalculateFormValidate.js";
import createOwlCarouselReviews from './modules/carousels/createOwlCarouselReviews.js';
import initReviewsRating from "./modules/initReviewsRating.js";
import initFileUploadsHandler from "./modules/initFileUploadsHandler.js";
import initCalcPopupScript from "./modules/popups/initCalcPopupScript.js";
import initButtonsOnClickDownload from "./modules/initButtonsOnClickDownload.js";
import initModalCreateOrder from "./modules/createOrderHandlers/initModalCreateOrder.js";
import initLeadHunter1Popup from "./modules/popups/initLeadHunter1Popup.js";
import initLeadHunter2Popup from "./modules/popups/initLeadHunter2Popup.js";
import initSelect2 from "./modules/initSelect2.js";
import checkEmailCreateOrderForm from "./modules/formHandlers/checkEmailCreateOrderForm.js";
import initSendToCrmForm from "./modules/formHandlers/initSendToCrmForm.js";
import initSendToCrmFromMainForm from "./modules/formHandlers/initSendToCrmFromMainForm.js";
import initSendToCrmSubscribeForm from "./modules/formHandlers/initSendToCrmSubscribeForm.js";
import initSendToCrmTopForm from "./modules/formHandlers/initSendToCrmTopForm.js";
// import initCarouselMore from "./modules/carousels/initCarouselMore.js";
import initHandlerOpenModal from "./modules/modals/initHandlerOpenModal.js";
import initModalOverlay from "./modules/modals/initModalOverlay.js";
import initSearchClick from "./modules/initSearchClick.js";
import initHamburgerModalRemover from "./modules/initHamburgerModalRemover.js";
import initHandlerCloseModal from "./modules/modals/initHandlerCloseModal.js";
import initRangeSlider from "./modules/initRangeSlider.js";
import initTabs from "./modules/initTabs.js";
import initSelectFun from "./modules/initSelectFun.js";
import initShowPopups from "./modules/popups/initShowsPopups.js";
import initStepsCarousel from "./modules/carousels/initStepsCarousel.js";
import initSimilarCarousel from "./modules/carousels/initSimilarCarousel.js";
import initDropDownList from "./modules/initDropdownList.js";
import initSearchGoods from "./modules/initSearchGoods.js";
import initHeaderHamburger from "./modules/initHeaderHamburger.js";
import initFilterTitleMobile from "./modules/initFilterTitleMobile.js";
import { initPhoneMasks } from "./modules/initPhoneMasks.js";
import { initPopupHandlers } from './modules/popups/initPopupHandlers.js';
import InitCourseSearchInput from "./modules/initCourseSearchInput.js";
import initYandexEvent from "./modules/initYandexEvent.js";


window.addEventListener('DOMContentLoaded', () => {
  // Utils
  // ---------------------------------

  iosVhFix();

  // Modules
  // ---------------------------------

  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {
    initRangeSlider();
    initTabs();
    initSelectFun();
    initShowPopups();
    initStepsCarousel();
    initSimilarCarousel();
    initDropDownList();
    initSearchGoods();
    initHeaderHamburger();
    initFilterTitleMobile();
    // initCarouselMore(); скрываю так как отказывается от кнопки показать больше
    initHandlerOpenModal();
    initModalOverlay();
    initSearchClick();
    initHamburgerModalRemover();
    initHandlerCloseModal();
    initSelect2();
    checkEmailCreateOrderForm();
    initSendToCrmForm();
    initSendToCrmFromMainForm();
    initSendToCrmSubscribeForm();
    initSendToCrmTopForm();
    createSelectsOptions();
    initCalculateFormValidate();
    initCalculatorForm();
    initCalcPopupScript();
    createOwlCarouselReviews();
    initReviewsRating();
    initPopupHandlers()
    initButtonsOnClickDownload();
    initModalCreateOrder();
    initFileUploadsHandler();
    initPhoneMasks();
    initLeadHunter1Popup();
    initLeadHunter2Popup();
    InitCourseSearchInput();
    initYandexEvent();
  });
});

// ❗❗❗ обязательно установите плагины eslint, stylelint, editorconfig в редактор кода.

// привязывайте js не на классы, а на дата атрибуты (data-validate)

// вместо модификаторов .block--active используем утилитарные классы
// .is-active || .is-open || .is-invalid и прочие (обязателен нейминг в два слова)
// .select.select--opened ❌ ---> [data-select].is-open ✅

// выносим все в дата атрибуты
// url до иконок пинов карты, настройки автопрокрутки слайдера, url к json и т.д.

// для адаптивного JS используейтся matchMedia и addListener
// const breakpoint = window.matchMedia(`(min-width:1024px)`);
// const breakpointChecker = () => {
//   if (breakpoint.matches) {
//   } else {
//   }
// };
// breakpoint.addListener(breakpointChecker);
// breakpointChecker();

// используйте .closest(el)
