import { closeLoaderGR, openLoaderGR } from "../loaders/loader";
import MainSettings from "../../data/mainSettings";

const makeOrderGR = (orderId, email) => {
  const popupDownload = $('#popup-download');

  popupDownload.find('.modal-footer, .close, .js-email').hide();
  popupDownload.find('.js-message').html('Загрузка...').show();
  openLoaderGR();
  ym(MainSettings.metrika, "reachGoal", "ORDER")
  const token = $('meta[name="csrf-token"]').attr('content');

  fetch('/gotovye-raboty/forms/make/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json, text-plain, */*",
      "X-Requested-With": "XMLHttpRequest",
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify({ orderId: orderId, email: email })
  }).then(response => response.json())
    .then((result) => {
      console.log(result);
      if (result && result.status && result.redirect) {
        window.location.href = result.redirect;
      } else {
        popupDownload.find('.modal-footer, .close').show();
        popupDownload
          .find('.js-message')
          .html(
            result && result.error
              ? result.error
              : 'Произошла ошибка, повторите попытку!'
          )
          .show();
        closeLoaderGR();
      }
      console.log(result);
    }).catch((error) => {
      closeLoaderGR();
      console.error(error);
      popupDownload.find('.modal-footer, .close').show();
      popupDownload
        .find('.js-message')
        .html('Произошла ошибка, повторите попытку!')
        .show();
    })

  /*   $.post(
      '/gotovye-raboty/make/',
      { orderId: orderId, email: email },
      function (res) {
        console.log(res);
        if (res && res.status && res.redirect) {
          window.location.href = res.redirect;
        } else {
          popupDownload.find('.modal-footer, .close').show();
          popupDownload
            .find('.js-message')
            .html(
              res && res.error
                ? res.error
                : 'Произошла ошибка, повторите попытку!'
            )
            .show();
          closeLoaderGR();
        }
        console.log(res);
      },
      'json'
    ).fail(function (e) {
      closeLoaderGR();
      console.error(e);
      popupDownload.find('.modal-footer, .close').show();
      popupDownload
        .find('.js-message')
        .html('Произошла ошибка, повторите попытку!')
        .show();
    }); */
};

export default makeOrderGR
